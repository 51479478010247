
import { computed, defineComponent, onMounted, ref, toRefs, unref } from 'vue'
import { useDateFns } from '@/composables/locale/useDateFns'
import { useNumeral } from '@/composables/useNumeral'
// import CommentsModal from './CommentsModal.vue'
import { currentUser } from '@/composables/user/currentUser'
import { toDate } from '@/composables/utils/toDate'
import { useProfile } from '@/composables/profile/useProfile'
import { getAnalytics } from '@/composables/analytics/useAnalytics'

export default defineComponent({
  // components: {
  //   CommentsModal,
  // },

  props: {
    post: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { post } = toRefs(props)
    
    const thumbnail = ref('')
    const type = ref('')

    const { format } = useDateFns()

    const { user } = currentUser()

    const { profile } = useProfile()

    const {
      fetchPostThumbnail
    } = getAnalytics()

    const {
      counter
    } = useNumeral()

    const publishDate = computed(() => {
      return format(toDate(unref(post).dateUtc))
    })

    const showSaveData = computed(() => {
      return unref(post).source ? unref(post).source.toLowerCase() === 'instagram' : false
    })

    const showReachData = computed(() => {
      return unref(post).source ? unref(post).source.toLowerCase() === 'instagram' : false
    })

    const reachCount = computed(() => {
      return counter(unref(post).insights?.reach || 0)
    })

    const likesCount = computed(() => {
      return counter(unref(post).insights?.likes || 0)
    })

    const commentsCount = computed(() => {
      return counter(unref(post).insights?.comments || 0)
    })

    const savedCount = computed(() => {
      return counter(unref(post).insights?.saved || 0)
    })

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/img/no-image.png')

      try {
        const thumb = await fetchPostThumbnail(profile.value.id, unref(post).socialId, unref(post).source)
        if (thumb) {
          element.src = thumb 
        }
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(() => {
      type.value = unref(post).media?.type
      thumbnail.value =  unref(post).media?.thumbnail || unref(post).media?.url || require('@/assets/img/no-image.png')
    })

    const unlockExperimentalFeatures = computed(() => {
      const userIds = ['skHi69P8radCAUp0adgipYY6tpi1', '18fSShP4CTPDJzm7xxaG895JLPo1']
      return userIds.includes(unref(user).id)
    })

    return {
      thumbnail,
      type,
      publishDate,
      showSaveData,
      showReachData,
      reachCount,
      likesCount,
      commentsCount,
      savedCount,
      unlockExperimentalFeatures,
      imageFallback
    }
  }
})
